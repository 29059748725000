
html{
    height: 100%;
}
body{
    box-sizing: border-box;
    background-color: #0C0D16;
    background-size: cover;
    color: #fff;
    margin: 0;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-spinner::-webkit-outer-spin-button,
    .no-spinner::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .no-spinner {
        -moz-appearance: textfield;
    }
}

@layer base {
    @font-face {
        font-family: 'MontMed';
        src: url(./fonts/Montserrat-Medium.ttf) format('truetype');
        font-style: normal;
        font-weight: 500;
        font-display: swap;
    }
    @font-face {
        font-family: 'MontBold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(./fonts/Montserrat-Bold.ttf) format('truetype');
    }
    @font-face {
        font-family: 'MontSemi';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(./fonts/Montserrat-Regular.ttf) format('truetype');
    }
    @font-face {
        font-family: 'MontIt';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(./fonts/Montserrat-Italic.ttf) format('truetype');
    }
    @font-face {
        font-family: 'MontL';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(./fonts/Montserrat-Light.ttf) format('truetype');
    }
    @font-face {
        font-family: 'MontBoldIt';
        font-style: normal;
        font-display: swap;
        src: url(./fonts/Montserrat-BoldItalic.ttf) format('truetype');
    }

}



.article-menu-list{
    margin-left: 1.5em;
    margin-bottom: 1em;
}
.article-menu-list li{
    margin-bottom: .5em;
    line-height: 1.5em;
    position: relative;
    padding-left: 2.1em;
    counter-increment: list-item;
}
.article-menu-list li::before{
    content: counter(list-item);
    position: absolute;
    left: 0;
    color: #008fdf;
    font-size: 1.25em;
    text-align: center;
    width: 1em;
    line-height: 1.5em;
    font-family: 'MontSemi', Arial, sans-serif;
    transform: translateY(-12%);
}
.article-menu-list li a{
    color: #0171a6;
    border-bottom: 1px dashed #0171a6;
    text-decoration: none;
    cursor: pointer;
}
.terms-section{
    font-size: 1em;
    font-weight: 400;
    margin: 3.5em 0 1.25em;
    max-width: initial;
    line-height: 1.25em;
}
.terms-section span{
    color: white;
    font-size: 1.25em;
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
}
.terms-section label{
    color: #F9943C;
    display: block;
    font-size: 2em;
    line-height: 1.25;
}
.with-counter{
    padding-left: 3.5em;
    position: relative;
    line-height: 1.5;
    margin-bottom: 1em;
}
.counter{
    color: #008FDF;
    font-size: 1.25em;
    font-weight: 600;
    left: 0;
    line-height: 1.1;
    position: absolute;
    top: 0;
}
.with-marker{
    padding-left: 2.25em;
}
.deep{
    margin-left: 3.5em;
}
.nogap{
    margin-top: 0;
}
.marker{
    color: #008FDF;
    font-size: 1.25em;
    font-weight: 600;
    left: 0;
    line-height: 1.1;
    position: absolute;
    top: 0;
}

.content-terms p{
    line-height: 1.5;
    margin-bottom: 1em;
}

.input-wabbr::before{
    box-sizing: border-box;
    border-radius: .5rem;
    font-size: 1.625em;
    height: 2.2em;
    width: 100%;
    background: rgba(0,0,0,0.5);
    content: "";
    display: block;
    position: absolute;
    pointer-events: none;
    border: 1px solid;
    font-family: Arial, sans-serif;
}

.ui-select-list::before{
    content: "";
    height: 1px;
    position: absolute;
    display: block;
    left: 1em;
    right: 1em;
    top: 0;
    background-color:rgba(255,255,255,0.2);
    z-index: 1;
    pointer-events: none;
}

.ui-select-option::after{
    content: "";
    display: block;
    position: absolute;
    left: 1em;
    right: 1em;
    background-color:rgba(255,255,255,0.4);
    height: 1px;
    bottom: 0;
    width: auto;
    font-size: inherit;
    clear: both;
}
.transition-height{
    transition: height 0.2s linear;
}

.transition-rotate{
    transition: transform .3s ease;
}
